import { FormControl, MenuItem, Select } from '@mui/material';
import i18next from 'i18next';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHeart, FaHome } from 'react-icons/fa';
import { MdAdminPanelSettings, MdCloudUpload, MdOutlineSettings } from 'react-icons/md';
import { RiUserFill } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from 'states/user';

import { Header as CounterfeitDetectionHeader } from 'components/CounterfeitDetection/Header';
import { VisualSearch } from 'components/SmartCatalog/VisualSearch';
import { useVisualSearch } from 'components/SmartCatalog/contexts';
import { Popup } from 'components/utils';
import { DisconnectButton } from 'components/utils/DisconnectButton';
import { Search } from 'components/utils/Search';

import { isChristmas } from 'utils/functions';
import { Path, paths } from 'utils/paths';

import Logo from 'assets/images/Logo.png';
import LogoChristmas from 'assets/images/LogoChristmas.png';
import fr from 'assets/images/france.png';
import en from 'assets/images/greatBritain.png';
import lockeoDetection from 'assets/images/lockeoDetection.png';
import visualSearchSVG from 'assets/images/visualSearch.svg';

import style from './index.module.scss';

interface HeaderProps {
  specification?: boolean;
  onVisualSearch?: (f: File) => any;
}

const langs = [
  { r: 'en', flag: en },
  { r: 'fr', flag: fr },
];

export const Header: FunctionComponent<HeaderProps> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [user] = useUser();
  const [up, setUp] = useState(false);
  const [file, setFile] = useState<File>();
  const [_, setVisualSearch] = useVisualSearch();

  const name = user ? (user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : `${user.email}`) : t('noUser');

  const [__, firstPath, secondPath] = location.pathname.split('/');

  const handleSearch = (f: File) => {
    navigate(`/${paths.catalog}`);
    setUp(false);
    setVisualSearch(f);
    setFile(undefined);
  };

  let isDetection = false,
    isCatalog = false;
  if (user && Path.exists(firstPath)) {
    const p = Path.get(firstPath);
    isDetection = p == paths.lockeoDetection;
    isCatalog = p == paths.catalog;
  }

  const l = i18next.resolvedLanguage.toUpperCase();

  return (
    <header>
      <div className={style.main}>
        <a href='https://textilia.fr/' draggable={false}>
          <img src={isChristmas() ? LogoChristmas : Logo} alt='TextilIA logo' draggable={false} />
        </a>
        <div className={style.center}>
          {isDetection ? (
            <img src={lockeoDetection} alt='Lockeo logo' draggable={false} />
          ) : (
            isCatalog && (
              <div className={style.search}>
                <Search placeholder={t('searchForPattern')} />
                <button className={style.visual} onClick={() => setUp(true)}>
                  <img src={visualSearchSVG} draggable={false} />
                  <div>
                    <div>{t('visualSearchDesc')}</div>
                    <div />
                  </div>
                </button>
                <Popup className={style.popup} up={up} onClose={() => (setUp(false), setFile(undefined))}>
                  <VisualSearch onSearch={handleSearch} onExit={() => setFile(undefined)} file={file} onFileChange={f => setFile(f)} />
                </Popup>
              </div>
            )
          )}
        </div>
        <div className={style.nav}>
          <div>
            {user && (
              <>
                {user.is_staff && (
                  <>
                    {isDetection && (
                      <Link
                        to={`/${paths.lockeoDetection}/${paths.settings}`}
                        className={secondPath == paths.settings.url ? style.mainSelected : undefined}
                      >
                        <MdOutlineSettings />
                      </Link>
                    )}
                    {isCatalog && (
                      <>
                        <Link className={secondPath == paths.selections.url ? style.mainSelected : undefined} to={`/${paths.catalog}/${paths.selections}`}>
                          <FaHeart />
                        </Link>
                        <Link
                          className={secondPath == paths.digitalization.url ? style.mainSelected : undefined}
                          to={`/${paths.catalog}/${paths.digitalization}`}
                        >
                          <MdCloudUpload />
                        </Link>
                      </>
                    )}
                    <Link to={`/${paths.admin}`} className={firstPath == paths.admin.url ? style.mainSelected : undefined}>
                      <MdAdminPanelSettings />
                    </Link>
                  </>
                )}
                <Link to={`/${paths.me}`} className={firstPath == paths.me.url ? style.mainSelected : undefined}>
                  <RiUserFill />
                </Link>
                <Link to={`/${paths.home}`} className={firstPath == paths.home.url ? style.mainSelected : undefined}>
                  <FaHome />
                </Link>
              </>
            )}
            <div className={style.lang}>
              <FormControl sx={{ m: 0, p: 0, minWidth: 80 }} size='small'>
                <Select value={l} onChange={e => i18next.changeLanguage(e.target.value.toLocaleLowerCase())}>
                  {langs
                    .map(f => f.r.toUpperCase())
                    .map((f, i) => {
                      return (
                        <MenuItem key={`lang-${i}`} value={f} style={{ display: i18next.resolvedLanguage.toLocaleUpperCase() == f ? 'none' : 'auto' }}>
                          {f}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
            {user && <DisconnectButton>{t('logout')}</DisconnectButton>}
          </div>
          {user && <p>{name}</p>}
        </div>
      </div>
      {isDetection && <CounterfeitDetectionHeader />}
    </header>
  );
};
