export const handleUploadProgress = (e, totalProgress, xhrProgress, center) => {
    const { loaded, total } = e;
    xhrProgress(calculateProgress(totalProgress, { upload: { loaded, total } }, center));
};
export const handleDownloadProgress = (e, totalProgress, xhrProgress, center) => {
    const { loaded, total } = e;
    xhrProgress(calculateProgress(totalProgress, { download: { loaded, total } }, center));
};
export const calculateProgress = (base, addon, center) => {
    const { upload, download } = base;
    base.upload = calculateInnerProgress(base.upload, addon.upload);
    base.download = calculateInnerProgress(base.download, addon.download);
    return {
        upload,
        download,
        total: {
            get loaded() {
                return upload.loaded + download.loaded;
            },
            get total() {
                return upload.total + download.total;
            },
            get percentage() {
                return ((upload.percentage + download.percentage) / (1 / (center ?? 0.5)));
            },
        },
    };
};
export const calculateInnerProgress = (progress, addon) => {
    return {
        loaded: addon?.loaded ?? progress.loaded,
        total: addon?.total ?? progress.total,
        get percentage() {
            if (this.total == 0)
                return 0;
            return (this.loaded / this.total) * 100;
        },
    };
};
export const totalProgressInitialiser = () => ({
    upload: progressInitialiser(),
    download: progressInitialiser(),
    total: progressInitialiser(),
});
export const progressInitialiser = () => ({
    loaded: 0,
    percentage: 0,
    total: 0,
});
