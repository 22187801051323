/* eslint-disable react/prop-types */
import { Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { ErrorBoundary } from 'components/utils';

import type { MatchCounterfeit } from 'interfaces';

import { Path } from 'utils/paths';

import styles from './index.module.scss';

const Counterfeit: FunctionComponent = () => {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <ErrorBoundary>
      <Query<MatchCounterfeit> query={`get_counterfeit_from_id/?counterfeit_id=${params.id}`}>
        {({ data: counterfeit, loading }) => {
          if (loading) return <div>Chargement...</div>;

          return (
            <div className={styles.main}>
              <div>
                <div>
                  <img src={`data:image/gif;base64,${counterfeit.scrap.scrap}`} alt={`Référence ${counterfeit.scrap.product_url}`} draggable={false} />
                </div>
                <div className={styles.mainData}>
                  <div>
                    <h3>{t('counterfeitApprovedBy')} :</h3>
                    <h3>{counterfeit.counterfeit_user}</h3>
                  </div>
                  <div>
                    <h3>{t('counterfeitApprovedOn')} :</h3>
                    <h3>{new Date(counterfeit.counterfeit_date).toLocaleDateString('fr')}</h3>
                  </div>
                  <div>
                    <h3>{t('matchApprovedBy')} :</h3>
                    <h3>{counterfeit.validation_user}</h3>
                  </div>
                  <div>
                    <h3>{t('matchApprovedOn')} :</h3>
                    <h3>{new Date(counterfeit.validation_date).toLocaleDateString('fr')}</h3>
                  </div>
                </div>
                <div className={styles.pattern}>
                  <Link to={`/${Path.join('lockeoDetection', 'management', 'patterns')}/${counterfeit.pattern.id}`} draggable={false}>
                    <img src={`data:image/gif;base64,${counterfeit.pattern.thumbnail}`} alt={`Image du motif`} draggable={false} />
                  </Link>
                  <div>
                    <p>{t('pattern')}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    </ErrorBoundary>
  );
};

export default Counterfeit;
