import '@generalizers/prototype-expansion';
import { SnackbarProvider } from 'notistack';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';

import { App } from 'components/App';
import { ErrorBoundary } from 'components/utils';

import 'utils/aws-config';

import './i18n';

const root = createRoot(document.getElementById('root')!);

root.render(
  <ErrorBoundary>
    <Router>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </Router>
  </ErrorBoundary>,
);
