import type { NamedEntity } from 'interfaces';

export enum PatternType {
  Pattern = 0,
  Book = 1,
  Rebrack = 2,
}

export default interface Pattern extends NamedEntity {
  thumbnail: string;
  uploaded_at: string;
  type: PatternType;
}
