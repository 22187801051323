import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { requestOptionsMerge } from '../../../utils/api';
import { createCacheKey } from '../../../utils/cache';
import { requestLog } from '../../../utils/log';
import ErrorBoundary from '../ErrorBoundary';
import { Query } from '../Query';
import { useQueryOptions } from '../QueryOptionsProvider';
import { createRequest } from './create';
import { deleteRequest } from './delete';
import { updateRequest } from './update';
import objectHash from 'object-hash';
import { useEffect, useMemo } from 'react';
/**
 * This component is used to simplify `CRUD` operations.
 * You give it the `endpoint(s)` to which you want to do requests and it will handle
 * all of the tasks in the background and update the state of the variables
 * it gives you from the child function.
 *
 * Make sure to define the QueryOptions param with the `QueryOptionsProvider` component
 *
 * Go to the [examples directory](https://bitbucket.org/neovision/react-query/src/master/src/examples) to see examples
 */
export const CRUD = ({ children, endpoints, onCreated, onUpdated, onDeleted, override, ...options }) => {
    const endpointsHash = objectHash(endpoints);
    const [createEndpoint, readEndpoint, updateEndpoint, deleteEndpoint] = useMemo(() => handleEndpoints(endpoints), [endpointsHash]);
    const [queryOptionsState] = useQueryOptions();
    const queryOptions = requestOptionsMerge([queryOptionsState, options]);
    const { verbosity, mode } = queryOptionsState;
    const { data } = options;
    useEffect(() => {
        requestLog(mode, verbosity, 5, `[endpoints]`, `[C]${createEndpoint.endpoint}`, `[R]${readEndpoint.endpoint}`, `[U]${updateEndpoint.endpoint}`, `[D]${deleteEndpoint.endpoint}`);
    }, [endpointsHash]);
    const { endpoint, ...rRest } = readEndpoint;
    return (_jsx(ErrorBoundary, { children: _jsx(Query, { query: endpoint, ...options, ...rRest, children: (res) => {
                const { forceRefresh } = res;
                const type = Array.isArray(res.data) ? 'array' : 'item';
                const cacheKey = createCacheKey(endpoint, data);
                return (_jsx(_Fragment, { children: children({
                        handleCreate: createRequest({
                            endpoint: requestOptionsMerge([queryOptions, createEndpoint], createEndpoint.override ?? override),
                            onCompleted: onCreated,
                            cacheKey,
                            ...res,
                        }),
                        read: res,
                        handleUpdate: updateRequest({
                            endpoint: requestOptionsMerge([queryOptions, updateEndpoint], updateEndpoint.override ?? override),
                            onCompleted: onUpdated,
                            type,
                            cacheKey,
                            ...res,
                        }),
                        handleDelete: deleteRequest({
                            endpoint: requestOptionsMerge([queryOptions, deleteEndpoint], deleteEndpoint.override ?? override),
                            type,
                            cacheKey,
                            onCompleted: onDeleted,
                            ...res,
                        }),
                        manualUpdate: res.manualUpdate,
                    }, forceRefresh) }));
            } }) }));
};
const handleEndpoints = (endpoints) => {
    const endpointsOrStrings = typeof endpoints == 'string'
        ? new Array(4).fill(endpoints)
        : [endpoints.create, endpoints.read, endpoints.update, endpoints.delete];
    return endpointsOrStrings.map((endpointOrString) => {
        if (typeof endpointOrString == 'string')
            return {
                endpoint: endpointOrString,
            };
        return endpointOrString;
    });
};
