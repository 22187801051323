import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-unsafe-finally */
import React from 'react';
import styles from './index.module.scss';
class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        error: { e: undefined, info: undefined },
    };
    componentDidCatch = (e, info) => {
        console.error(e);
        this.setState({ hasError: true, error: { e, info } });
    };
    render() {
        const { children } = this.props;
        const { hasError, error } = this.state;
        if (!hasError)
            return _jsx(React.Fragment, { children: children });
        return (_jsxs("div", { className: styles.main, children: [_jsx("div", { children: error?.e?.message }), _jsx("hr", {}), _jsx("div", { children: error?.info?.componentStack.split('\n').map((v) => (_jsx(_Fragment, { children: _jsx("div", { children: v }) }))) })] }));
    }
}
export default ErrorBoundary;
