import { Query } from '@neovision/react-query';
import hash from 'object-hash';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Size } from 'components/SmartCatalog/Catalog';
import { ListFooter } from 'components/SmartCatalog/Catalog/ListFooter';
import { useFiltersImages, usePage, useVisualSearch } from 'components/SmartCatalog/contexts';
import { SolutionItem } from 'components/utils/SolutionItem';

import { paths } from 'utils/paths';
import { PAGE_MAX_ELEMENTS } from 'utils/settings';

import styles from './index.module.scss';

interface PatternListProps {
  size: Size;
  search?: string;
}

export const PatternList: FunctionComponent<PatternListProps> = ({ size, search }) => {
  const { t } = useTranslation();
  const [[filters], [imageSrc]] = useFiltersImages();
  const [image] = useVisualSearch();
  const [page] = usePage();

  const col = size == 'small' ? 7 : size == 'medium' ? 5 : 3;

  const { tags, dateBegin, dateEnd, all_books, books, all_rebracks, rebracks, pattern_id, text } = filters;

  const formData = useMemo(() => {
    const formData = new FormData();

    formData.append(
      'data',
      JSON.stringify({
        filter: {
          tags: Object.entries(tags)
            .filter(([, v]) => v.value)
            .map(([k]) => k),
          dateBegin: dateBegin.value || undefined,
          dateEnd: dateEnd.value || undefined,
          all_books: all_books && !all_rebracks,
          all_rebracks: all_rebracks && !all_books && books.length == 0,
          books,
          rebracks,
        },
        text,
        params: { page_number: page, images_by_page: PAGE_MAX_ELEMENTS },
      }),
    );
    image && imageSrc && formData.append('image', image);
    pattern_id && formData.append('pattern_id', pattern_id.toString());

    return formData;
  }, [hash(filters), hash(image ?? {}), imageSrc, page]);

  return (
    <div className={styles.main}>
      <Query<{ ids: number[]; count: number }>
        query={search ? `get_pattern_from_name?query=${search}` : 'patterns/'}
        method={search ? 'GET' : 'POST'}
        data={formData}
        cache={0}
      >
        {({ data, fetching }) => {
          const { ids, count } = data ?? {};

          const patterns = !fetching ? ids : [...new Array(PAGE_MAX_ELEMENTS)];
          const patternCount = count ?? 0;

          return (
            <>
              <div className={styles.patterns}>
                {patterns.map((patternId, i) => {
                  return (
                    <SolutionItem
                      key={i}
                      url={`get_pattern_from_id?id=${patternId}&thumbnail=true`}
                      length={patterns.length}
                      index={i}
                      col={col}
                      active={!!patternId}
                      link={({ id }) => `/${paths.catalog}/${paths.product}/${id}`}
                      buttonLink={paths.catalog.toString()}
                    />
                  );
                })}
              </div>
              <ListFooter count={patternCount} length={patterns.length} item={t('patterns')} />
            </>
          );
        }}
      </Query>
    </div>
  );
};
