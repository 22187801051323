import { Alert, ListItemText, MenuItem, Select, Skeleton, TextField } from '@mui/material';
import { Query, StateFunction } from '@neovision/react-query';
import hash from 'object-hash';
import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineImageNotSupported } from 'react-icons/md';

import { maxBookDate, minBookDate } from 'components/SmartCatalog/Catalog';
import { useFilters } from 'components/SmartCatalog/contexts';
import { BlackButton } from 'components/utils/Button/BlackButton';
import { Dropdown } from 'components/utils/Dropdown';

import type Book from 'interfaces/Metadata';
import type { Pattern } from 'interfaces/pattern';

import styles from './index.module.scss';

interface BookFilterProps {
  books: Book[];
  onFilter: (books: number[]) => any;
}

export const BookFilter: FunctionComponent<BookFilterProps> = ({ books, onFilter }) => {
  const [selected, setSelected] = useState<number[]>([]);
  const { t } = useTranslation();

  const [filters] = useFilters();
  const {
    dateBegin: { value: begin },
    dateEnd: { value: end },
  } = filters;
  const [dateBegin, setDateBegin] = useState<number>();
  const [dateEnd, setDateEnd] = useState<number>();
  const [collections, setCollections] = useState<string[]>([]);
  const [clothTypes, setClothTypes] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [buildings, setBuildings] = useState<string[]>([]);
  const [rooms, setRooms] = useState<string[]>([]);
  const [shelves, setShelves] = useState<string[]>([]);

  const handleSelected = (id: number) => () => {
    if (selected.includes(id)) selected.remove(id);
    else selected.push(id);
    setSelected([...selected]);
  };

  useEffect(() => {
    setSelected([...filters.books]);
  }, [hash(filters.books)]);

  const filtered = books.filter(({ collection: coll, cloth_type, site_location, building_location, room_location, shelf_location, year }) => {
    return (
      ((dateBegin == undefined && dateEnd == undefined) ||
        (year != undefined &&
          ((dateBegin == undefined && dateEnd != undefined && year <= dateEnd) ||
            (dateBegin != undefined && dateEnd == undefined && year >= dateBegin) ||
            (dateBegin != undefined && dateEnd != undefined && year >= dateBegin && year <= dateEnd)))) &&
      (collections.length == 0 || collections.includes(coll ?? '')) &&
      (clothTypes.length == 0 || clothTypes.includes(cloth_type ?? '')) &&
      (locations.length == 0 || locations.includes(site_location ?? '')) &&
      (buildings.length == 0 || buildings.includes(building_location ?? '')) &&
      (rooms.length == 0 || rooms.includes(room_location ?? '')) &&
      (shelves.length == 0 || shelves.includes(shelf_location ?? ''))
    );
  });

  const correctFrom = dateBegin != undefined && (dateBegin < (begin ?? minBookDate) || dateBegin > (dateEnd ?? maxBookDate));
  const correctTo = dateBegin != undefined && dateEnd != undefined && (dateEnd > (end ?? maxBookDate) || dateEnd <= dateBegin);

  const filterOn = useCallback(
    (
      k: keyof Pick<Book, 'collection' | 'cloth_type' | 'room_location' | 'building_location' | 'site_location' | 'shelf_location'>,
      [state, setState]: [string[], Dispatch<SetStateAction<string[]>>],
    ) => {
      return (
        <Select
          multiple
          value={state}
          onChange={({ target: { value } }) => setState(typeof value === 'string' ? value.split(',') : value)}
          renderValue={selected => selected.join(', ')}
          MenuProps={{ sx: { height: '300px' } }}
          size='small'
          sx={{ width: '100%' }}
        >
          {books
            .map(b => b[k] || '')
            .reduce<string[]>((p, c) => {
              if (!p.includes(c) && c != '') p.push(c);
              return p;
            }, [])
            .sort((c1, c2) => c1.localeCompare(c2))
            .map((c, i) => (
              <MenuItem key={i} value={c}>
                <input className={styles.check} type={'checkbox'} checked={state.includes(c)} readOnly />
                <ListItemText primary={c} />
              </MenuItem>
            ))}
        </Select>
      );
    },
    [books],
  );

  return (
    <div className={styles.main} onMouseDown={e => e.stopPropagation()}>
      <div className={styles.pageTitle}>{t('bookshelve')}</div>
      <div className={styles.data}>
        <div className={styles.filters}>
          <div className={styles.title}>
            <div></div>
            <div>{t('filters')}</div>
          </div>
          <div className={styles.all}>
            <div>
              <Dropdown
                open={true}
                element={open => (
                  <div className={styles.dropdown}>
                    <div>Date</div>
                    <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                  </div>
                )}
                watchResize
              >
                <div className={styles.dropdownContent}>
                  <div className={styles.date}>
                    <div>{t('from')}</div>
                    <TextField
                      error={correctFrom}
                      type='number'
                      label={t('year')}
                      InputProps={{ inputProps: { min: begin ?? minBookDate, max: dateEnd ?? end ?? maxBookDate } }}
                      variant='standard'
                      helperText={correctFrom && t('yearFormat', { begin: minBookDate, end: filters.dateEnd.value ?? maxBookDate })}
                      FormHelperTextProps={{ style: { fontSize: '0.6em' } }}
                      value={dateBegin ?? ''}
                      onChange={({ target: { value: v } }) => {
                        const value = parseInt(v);
                        if (isNaN(value)) setDateBegin(undefined);
                        else setDateBegin(value);
                      }}
                    />
                  </div>
                  <div className={styles.date}>
                    <div>{t('to')}</div>
                    <TextField
                      className={styles.dateInput}
                      error={correctTo}
                      type='number'
                      label={t('year')}
                      InputProps={{ inputProps: { min: dateBegin ?? begin ?? minBookDate, max: end ?? maxBookDate } }}
                      variant='standard'
                      helperText={correctTo && t('yearFormat', { begin: minBookDate, end: filters.dateEnd.value ?? maxBookDate })}
                      value={dateEnd ?? ''}
                      FormHelperTextProps={{ style: { fontSize: '0.6em' } }}
                      onChange={({ target: { value: v } }) => {
                        const value = parseInt(v);
                        if (isNaN(value)) setDateEnd(undefined);
                        else setDateEnd(value);
                      }}
                    />
                  </div>
                </div>
              </Dropdown>
            </div>
            <div>
              <Dropdown
                open={true}
                element={open => (
                  <div className={styles.dropdown}>
                    <div>Collection</div>
                    <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                  </div>
                )}
              >
                <div className={styles.dropdownContent}>{filterOn('collection', [collections, setCollections])}</div>
              </Dropdown>
            </div>
            <div>
              <Dropdown
                open={true}
                element={open => (
                  <div className={styles.dropdown}>
                    <div>{t('fabricType')}</div>
                    <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                  </div>
                )}
              >
                <div className={styles.dropdownContent}>{filterOn('cloth_type', [clothTypes, setClothTypes])}</div>
              </Dropdown>
            </div>
            <div>
              <Dropdown
                open={true}
                element={open => (
                  <div className={styles.dropdown}>
                    <div>{t('location')}</div>
                    <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                  </div>
                )}
              >
                <div className={styles.dropdownContent}>{filterOn('site_location', [locations, setLocations])}</div>
              </Dropdown>
            </div>
            <div>
              <Dropdown
                open={true}
                element={open => (
                  <div className={styles.dropdown}>
                    <div>{t('building')}</div>
                    <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                  </div>
                )}
              >
                <div className={styles.dropdownContent}>{filterOn('building_location', [buildings, setBuildings])}</div>
              </Dropdown>
            </div>
            <div>
              <Dropdown
                open={true}
                element={open => (
                  <div className={styles.dropdown}>
                    <div>{t('room')}</div>
                    <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                  </div>
                )}
              >
                <div className={styles.dropdownContent}>{filterOn('room_location', [rooms, setRooms])}</div>
              </Dropdown>
            </div>
            <div>
              <Dropdown
                open={true}
                element={open => (
                  <div className={styles.dropdown}>
                    <div>{t('shelf')}</div>
                    <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                  </div>
                )}
              >
                <div className={styles.dropdownContent}>{filterOn('shelf_location', [shelves, setShelves])}</div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.books}>
            {filtered.length == 0 ? (
              <Alert severity='warning'>{t('noBooks')}</Alert>
            ) : (
              filtered.map(({ id, name }, i) => {
                return (
                  <StateFunction key={i}>
                    {() => {
                      const formData = useMemo(() => {
                        const d = new FormData();
                        d.append('data', JSON.stringify({ filter: { books: [id] }, params: { page_number: 1, images_by_page: 4 } }));
                        return d;
                      }, [id]);

                      return (
                        <Query<{ ids: number[] }> query={`patterns/`} data={formData} method='POST' loader={true}>
                          {({ data }) => (
                            <div
                              className={styles.card}
                              onClick={handleSelected(id)}
                              style={{ boxShadow: selected.includes(id) ? '0 0 2px 3px #00d8f4' : '0 0 1px 1px #eee' }}
                            >
                              <div className={styles.images}>
                                {data.ids.length == 0 ? (
                                  <div className={styles.no}>
                                    <MdOutlineImageNotSupported />
                                  </div>
                                ) : (
                                  data.ids.map((id, i) => {
                                    return (
                                      <Query<Pattern> key={i} query={`get_pattern_from_id?id=${id}&thumbnail=true`}>
                                        {({ data: pattern, loading }) => {
                                          if (loading) return <Skeleton variant='rectangular' />;
                                          return <img src={`data:png;base64,${pattern.thumbnail}`} draggable={false} />;
                                        }}
                                      </Query>
                                    );
                                  })
                                )}
                              </div>
                              <div className={styles.name}>
                                <div>{name}</div>
                                <input type={'checkbox'} checked={selected.includes(id)} readOnly={true} />
                              </div>
                            </div>
                          )}
                        </Query>
                      );
                    }}
                  </StateFunction>
                );
              })
            )}
          </div>
          <div className={styles.apply}>
            <BlackButton onClick={() => setSelected([])}>{t('deselectAll')}</BlackButton>
            <BlackButton onClick={() => setSelected(filtered.map(b => b.id))}>{t('selectAll')}</BlackButton>
            <BlackButton onClick={() => onFilter(selected)}>{t('apply')}</BlackButton>
          </div>
        </div>
      </div>
    </div>
  );
};
