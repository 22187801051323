import { Query } from '@neovision/react-query';
import hash from 'object-hash';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Size } from 'components/SmartCatalog/Catalog';
import { ListFooter } from 'components/SmartCatalog/Catalog/ListFooter';
import { useFiltersImages, usePage } from 'components/SmartCatalog/contexts';
import { SolutionItem } from 'components/utils/SolutionItem';

import { paths } from 'utils/paths';
import { PAGE_MAX_ELEMENTS } from 'utils/settings';

import styles from './index.module.scss';

interface RebrackListProps {
  size: Size;
}

export const RebrackList: FunctionComponent<RebrackListProps> = ({ size }) => {
  const { t } = useTranslation();
  const [[filters]] = useFiltersImages();
  const [page] = usePage();

  const col = size == 'small' ? 7 : size == 'medium' ? 5 : 3;

  const { dateBegin, dateEnd, rebracks } = filters;

  const formData = useMemo(() => {
    return {
      filter: {
        dateBegin: dateBegin.value,
        dateEnd: dateEnd.value,
        rebracks,
      },
      params: { page_number: page, images_by_page: PAGE_MAX_ELEMENTS },
    };
  }, [hash(filters), page]);

  return (
    <div className={styles.main}>
      <Query<{ ids: number[]; count: number }> query={`get_rebracks/?data=${JSON.stringify(formData)}`} cache={0}>
        {({ data, loading }) => {
          const { ids, count } = data ?? {};

          const rebracks = ids ?? [...new Array(PAGE_MAX_ELEMENTS)];

          return (
            <>
              <div className={styles.rebracks}>
                {rebracks.map((rebrackId, i) => {
                  return (
                    <SolutionItem
                      key={i}
                      url={`get_rebrack_from_id?id=${rebrackId}&thumbnail=true`}
                      length={rebracks.length}
                      active={!loading}
                      index={i}
                      col={col}
                      link={({ rebrack_group }) => `/${paths.catalog}/rebracks/${rebrack_group}/${rebrackId}`}
                      type={'rebrack'}
                    />
                  );
                })}
              </div>
              <ListFooter length={rebracks.length} count={count ?? 0} item={'Rebrack'} />
            </>
          );
        }}
      </Query>
    </div>
  );
};
