import { Skeleton } from '@mui/material';
import type { FormEvent, FunctionComponent } from 'react';
import { FaEdit } from 'react-icons/fa';

import styles from './index.module.scss';

interface TitleProps {
  loading?: boolean;
  edit?: boolean;
  handleEdit?: (edit: boolean) => any;
  handleSubmit?: (e: FormEvent) => any;
  value?: string;
}

export const Title: FunctionComponent<TitleProps> = ({ edit = false, value = '', handleEdit, handleSubmit, loading }) => {
  return (
    <div className={styles.main}>
      {edit ? (
        <>
          <input
            defaultValue={value ?? ''}
            onClick={e => e.stopPropagation()}
            onKeyDown={e => {
              if (e.key == 'Enter' || e.key == 'Escape') {
                handleEdit?.(false);
                handleSubmit?.(e);
              }
            }}
            autoFocus
          />
        </>
      ) : loading ? (
        <Skeleton />
      ) : (
        <>
          <div>{value}</div>
          <div
            className={styles.edit}
            onClick={e => {
              e.stopPropagation();
              handleEdit?.(true);
            }}
          >
            <FaEdit />
          </div>
        </>
      )}
    </div>
  );
};
