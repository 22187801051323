import { QueryOptionsProvider, useQuery } from '@neovision/react-query';
import { Auth } from 'aws-amplify';
import i18next from 'i18next';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { UserProvider } from 'states/user';

import { Annotation } from 'components/Annotation';
import { Admin, Login, ResetPassword, User } from 'components/App/Account';
import { Footer } from 'components/App/Footer';
import { Header } from 'components/App/Header';
import { Selection } from 'components/App/Selection';
import { CounterfeitDetection } from 'components/CounterfeitDetection';
import { Defense } from 'components/Defense';
import { SmartCatalog } from 'components/SmartCatalog';
import { FiltersImagesInitializer, ViewProvider, VisualSearchProvider } from 'components/SmartCatalog/contexts';
import { TextiliaLoader } from 'components/utils/TextiliaLoader';

import type { UserWithCompany } from 'interfaces';

import { isActive } from 'utils/checks';
import { paths } from 'utils/paths';

import 'scss/main.scss';

import Register from './Account/Register';

const { REACT_APP_BACKEND: domain, REACT_APP_MODE: mode } = process.env;
const accountLogin = ['login', 'signin'];
const accountRegister = ['register', 'signup'];
const accountReset = ['resetPassword', 'forgotPassword'];
const accountPaths = [...accountLogin, ...accountRegister, ...accountReset];

export const App: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [firstLocation, setFirstLocation] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  document.title = "Textil'IA";

  useEffect(() => {
    setFirstLocation(pathname);
  }, []);

  const getAuth = () =>
    Auth.currentSession()
      .then(session => {
        return {
          Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
          'Accept-Language': i18next.resolvedLanguage,
        };
      })
      .catch(() => {
        Auth.signOut();
        return {};
      });

  const checkServerError = (res: any) => {
    if (res.status >= 500 && res.status < 600) {
      enqueueSnackbar(t('serverErrorInfo'), { variant: 'error' });
      console.error('An error occured in the server');
    }
  };

  const {
    data: user,
    loading,
    manualUpdate: setUser,
  } = useQuery<UserWithCompany | undefined>({
    query: 'get_my_profile/',
    delay: 500,
    headers: getAuth,
    domain,
    onRejected: res => {
      checkServerError(res);
      if (res.status >= 400 && res.status < 500) Auth.signOut().finally(() => navigate('/', { replace: true }));
    },
  });

  return (
    <QueryOptionsProvider
      value={{
        domain,
        parameterType: 'path',
        mode,
        verbosity: process.env.REACT_APP_VERBOSITY_LEVEL,
        headers: getAuth,
        onRejected: rej => {
          checkServerError(rej);
          const { status, statusText } = rej;
          if (status == 401) {
            enqueueSnackbar(t('sessionExpiredInfo'), { variant: 'info' });
            setUser(undefined);
            navigate('/login', { replace: true });
          } else if (status == 413) {
            enqueueSnackbar(t('fileTooBigInfo'), { variant: 'error' });
            console.error(`The file sent is too big`, statusText);
          } else {
            const { data } = rej;
            if (Array.isArray(data) && data.length > 0) enqueueSnackbar(data[0], { variant: 'error' });
            else if (typeof data == 'object' && Object.keys(data).length > 0) {
              const { non_field_errors } = data;
              if (non_field_errors && Array.isArray(non_field_errors)) non_field_errors.forEach(err => enqueueSnackbar(err, { variant: 'error' }));
              else {
                const entries = Object.entries<any>(data);
                enqueueSnackbar(`${entries[0][0]} : ${entries[0][1]}`, { variant: 'error' });
              }
            } else if (statusText) enqueueSnackbar(statusText, { variant: 'error' });
            else enqueueSnackbar(t('badRequest'), { variant: 'error' });
          }
        },
      }}
    >
      <TextiliaLoader active={!!loading} />
      <UserProvider value={[user, setUser]}>
        <VisualSearchProvider>
          <FiltersImagesInitializer>
            <ViewProvider>
              <Header />
              <main data-testid='app'>
                {user ? (
                  (() => {
                    const { is_staff, company } = user;
                    const { detection_subscription_end, catalog_subscription_end } = company;
                    return (
                      <Routes>
                        {!loading && (
                          <>
                            {(isActive(detection_subscription_end) || is_staff) && (
                              <Route path={`/${paths.lockeoDetection}/*`} element={<CounterfeitDetection />} />
                            )}
                            {(isActive(catalog_subscription_end) || is_staff) && <Route path={`/${paths.catalog}/*`} element={<SmartCatalog />} />}
                            {is_staff && <Route path={`/${paths.defense}/*`} element={<Defense />} />}
                          </>
                        )}
                        <Route path='/' element={<Navigate to={`/${paths.home}`} replace />} />
                        <Route path={`/${paths.home}`} element={<Selection />} />
                        <Route path='/me' element={<User />} />
                        {is_staff && (
                          <>
                            <Route path='/admin' element={<Admin />} />
                            <Route path={`/${paths.annotation}`} element={<Annotation />} />
                          </>
                        )}
                        {firstLocation && (accountPaths.some(p => firstLocation.includes(p)) || firstLocation == '/') && (
                          <Route path='*' element={<Navigate to={'/'} replace />} />
                        )}
                        {firstLocation && <Route path='*' element={<Navigate to={firstLocation} replace />} />}
                      </Routes>
                    );
                  })()
                ) : (
                  <Routes>
                    {accountReset.map(p => (
                      <Route key={`route-${p}`} path={`/${p}/*`} element={<ResetPassword />} caseSensitive={false} />
                    ))}
                    {accountRegister.map(p => (
                      <Route key={`route-${p}`} path={`/${p}/*`} element={<Register />} caseSensitive={false} />
                    ))}
                    {accountLogin.map(p => (
                      <Route key={`route-${p}`} path={`/${p}`} element={<Login />} caseSensitive={false} />
                    ))}
                    {firstLocation && accountPaths.every(p => !firstLocation.includes(p)) && (
                      <Route path='*' element={<Navigate to={'/login'} replace />} />
                    )}
                    {firstLocation && accountPaths.some(p => firstLocation.includes(p)) && (
                      <Route path='*' element={<Navigate to={firstLocation} replace />} />
                    )}
                    <Route path='*' element={<Navigate to={'/login'} replace />} />
                  </Routes>
                )}
              </main>
            </ViewProvider>
          </FiltersImagesInitializer>
        </VisualSearchProvider>
        <Footer />
      </UserProvider>
    </QueryOptionsProvider>
  );
};
