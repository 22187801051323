import { Skeleton, Tooltip } from '@mui/material';
import { Query } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaHeart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { SelectionMenu } from 'components/SmartCatalog/Selections/Menu';
import { useImageSrc } from 'components/SmartCatalog/contexts';

import type { ElementType } from 'interfaces/Selection';

import styles from './index.module.scss';

export interface SolutionItemProps {
  url?: string;
  length: number;
  index: number;
  col: number;
  link: (item: any) => string;
  active: boolean;
  buttonLink?: string;
  type?: ElementType;
}

const loader = <Skeleton className={styles.placeholder} variant={'rectangular'} />;

export const SolutionItem: FunctionComponent<SolutionItemProps> = ({ url, length, index, col, active, link, buttonLink, type = 'pattern' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setImageSrc] = useImageSrc();
  const [open, setOpen] = useState(false);
  const [element, setElement] = useState<Element>();

  return (
    <Query<{ thumbnail: string; id: number; name: string }> query={url ?? ''} active={active}>
      {({ data: item }) => {
        return (
          <div
            className={styles.pattern}
            draggable={false}
            style={{ zIndex: length - index, width: `${100 / col}%` }}
            onClick={() => item && navigate(link(item))}
          >
            <div>
              <div>
                <div style={{ backgroundColor: item ? '#d9d9d9' : 'transparent' }}>
                  {item ? <img src={`data:image/png;base64,${item.thumbnail}`} draggable={false} /> : loader}
                </div>
                {item && (
                  <div className={styles.hover} onClick={e => e.stopPropagation()}>
                    <div className={styles.hoverHeader}>
                      <Tooltip title={item.name} placement='left'>
                        <div className={styles.name}>{item.name}</div>
                      </Tooltip>
                      <div className={styles.heart} onClick={() => setOpen(true)} ref={r => r && setElement(r)}>
                        <FaHeart />
                      </div>
                      <SelectionMenu element={element} onClose={() => setOpen(false)} open={open} id={item.id} type={type} />
                    </div>
                    {buttonLink && (
                      <div className={styles.button}>
                        <button
                          onClick={() => {
                            setImageSrc(`data:image/png;base64,${item.thumbnail}`);
                            navigate(`/${buttonLink}/search/${item.id}`);
                          }}
                        >
                          {t('similarProducts')}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Query>
  );
};
