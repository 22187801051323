import { Auth } from 'aws-amplify';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'states/user';

interface DisconnectButtonProps extends PropsWithChildren {
  className?: string;
}

export const DisconnectButton: FunctionComponent<DisconnectButtonProps> = ({ className, children }) => {
  const { t } = useTranslation();
  const [_, setUser] = useUser();
  const navigate = useNavigate();

  return (
    <button
      className={className}
      onClick={() => {
        if (window.confirm(t('sureDisconnect'))) {
          Auth.signOut().then(() => {
            setUser(undefined);
            navigate('/login');
          });
        }
      }}
    >
      {children}
    </button>
  );
};
