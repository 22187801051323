import type { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Ariane } from 'components/SmartCatalog/Ariane';
import { Catalog } from 'components/SmartCatalog/Catalog';
import { Page } from 'components/SmartCatalog/Catalog/Items/Pages/Page';
import { Product } from 'components/SmartCatalog/Catalog/Items/Product';
import { Rebrack } from 'components/SmartCatalog/Catalog/Items/Rebracks/Rebrack';
import { DropFile } from 'components/SmartCatalog/DropFile';
import { Selections } from 'components/SmartCatalog/Selections';
import { PageProvider } from 'components/SmartCatalog/contexts';

import { paths } from 'utils/paths';

import styles from './index.module.scss';

export const SmartCatalog: FunctionComponent = () => {
  document.title = 'Catalogue Intelligent';

  return (
    <PageProvider>
      <div className={styles.main}>
        <Routes>
          <Route
            path={`${paths.digitalization}`}
            element={
              <div className={styles.content}>
                <Ariane />
                <DropFile />
              </div>
            }
          />
          <Route
            path={`${paths.selections}`}
            element={
              <div className={styles.content}>
                <Ariane />
                <Selections />
              </div>
            }
          />
          <Route
            path={`${paths.product}/:id`}
            element={
              <div className={styles.content}>
                <Ariane />
                <Product />
              </div>
            }
          />
          <Route
            path={`${paths.books}/:id/:page`}
            element={
              <div className={styles.content}>
                <Page />
              </div>
            }
          />
          <Route
            path={`${paths.rebracks}/:id/:rebrack`}
            element={
              <div className={styles.content}>
                <Rebrack />
              </div>
            }
          />
          <Route path='*'>
            <Route path='search/:searchPatternId' element={<Catalog />} />
            <Route path='book/:bookId' element={<Catalog />} />
            <Route path='rebrack/:rebrackId' element={<Catalog />} />
            <Route path='*' element={<Catalog />} />
          </Route>
        </Routes>
      </div>
    </PageProvider>
  );
};
