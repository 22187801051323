import type { ReactElement } from 'react';
import { Fragment, useEffect, useState } from 'react';

export const isChristmas = () => {
  const m = new Date().getMonth();
  return m + 1 == 12;
};

export const useDebounce = (func: () => any, timeout = 300, effect: any[] = []) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        func();
      }, timeout),
    );
  }, effect);
};

export const riter = (n: number, e: ReactElement) => [...new Array(n)].map((_, i) => <Fragment key={i}>{e}</Fragment>);
