import { Query } from '@neovision/react-query';
import i18next from 'i18next';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { UserWithCompany } from 'interfaces';

import { isActive } from 'utils/checks';
import { paths } from 'utils/paths';

import lockeoDefense from 'assets/images/lockeoDefense.png';
import lockeoDetection from 'assets/images/lockeoDetection.png';
import lockeoProtection from 'assets/images/lockeoProtection.png';
import smartCatalog from 'assets/images/smartCatalog.png';
import smartCatalogEn from 'assets/images/smartCatalogEn.png';

import styles from './index.module.scss';

export const Selection: FunctionComponent = () => {
  const { t } = useTranslation();

  const subscribeText = t('subscribeText');

  return (
    <div className={styles.main}>
      <div className={styles.offer}>
        <h1>{t('allOffers')}</h1>
        <h2>{t('selectSolution')}</h2>
      </div>
      <div className={styles.solutions}>
        <Query<UserWithCompany> query={`get_my_profile/`}>
          {({ data: user, loading }) => {
            if (loading) return <div>Loading...</div>;

            const { is_staff, company } = user;
            const { protection_subscription_end, catalog_subscription_end, detection_subscription_end } = company;

            const [protection, catalog, detection] = [protection_subscription_end, catalog_subscription_end, detection_subscription_end].map(
              sub => !loading && (is_staff || isActive(sub)),
            );

            return (
              <>
                <div>
                  <img src={lockeoProtection} draggable={false} />
                  <div className={styles.title}>Protection</div>
                  <div className={styles.desc}>{t('lockeoProtectionDesc')}</div>
                  {protection ? (
                    <a href={`https://lockeo-protection.textilia.fr/`}>{t('access')}</a>
                  ) : (
                    <a href={`https://www.textilia.fr/lockeo-protection/`}>{t('more')}</a>
                  )}
                  <div>{protection || subscribeText}</div>
                </div>
                <div>
                  <img src={lockeoDetection} draggable={false} />
                  <div className={styles.title}>{t('detection')}</div>
                  <div className={styles.desc}>{t('lockeoDetectionDesc')}</div>
                  {detection ? <Link to={`/${paths.lockeoDetection}`}>{t('access')}</Link> : <a href='https://www.textilia.fr'>{t('more')}</a>}
                  <div>{detection || subscribeText}</div>
                </div>
                <div>
                  <img src={lockeoDefense} draggable={false} />
                  <div className={styles.title}>{t('defense')}</div>
                  <div className={styles.desc}>{t('lockeoDefenseDesc')}</div>
                  <a href='https://www.textilia.fr'>{t('more')}</a>
                  <div>{subscribeText}</div>
                </div>
                <div>
                  {i18next.resolvedLanguage == 'en' ? <img src={smartCatalogEn} draggable={false} /> : <img src={smartCatalog} draggable={false} />}
                  <div className={styles.title}></div>
                  <div className={styles.desc}>{t('catalogDesc')}</div>
                  {catalog ? <Link to={`/${paths.catalog}`}>{t('access')}</Link> : <a href='https://www.textilia.fr'>{t('more')}</a>}
                  <div>{catalog || subscribeText}</div>
                </div>
              </>
            );
          }}
        </Query>
      </div>
    </div>
  );
};
