import { createCacheKey, setCache } from './cache';
import { requestError, requestWarn } from './log';
import { buildHeader, restructureData } from './util';
import { XHRFetch } from './xhr';
export const requestOptionsMerge = (options, override = false) => {
    if (override)
        return Object.merge(...options);
    return Object.merge(...options, {
        onRejected: (rej) => options.forEach((o) => o.onRejected?.(rej)),
    });
};
export const request = function (domain, path, options = {
    method: 'GET',
}) {
    const { method = 'GET', headers, mode = 'production', onRejected, data, cache, ...rest } = options;
    const { body, contentType } = restructureData(data);
    const req = (headers) => {
        const endpoint = `${domain}/${path}`;
        const cacheHash = cache != 0 ? createCacheKey(path, data) : '';
        return XHRFetch(endpoint, {
            headers: buildHeader(headers, contentType),
            method,
            body,
            ...rest,
        })
            .then((res) => {
            setCache(cacheHash, res, cache);
            return res;
        })
            .catch((err) => {
            if (err.name == 'AbortError')
                requestWarn(mode, 0, 0, err.message);
            else {
                requestError(method, endpoint, `${err.status} ${err.statusText}`);
                onRejected?.(err);
                throw err;
            }
        });
    };
    return headers ? headers().then(req) : req({});
};
export const parseLoader = (loader) => {
    if (loader == undefined)
        return {};
    if (typeof loader == 'boolean')
        return { autoload: true };
    return loader;
};
