import type { FunctionComponent } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FiShare2 } from 'react-icons/fi';

import { Crop } from 'components/SmartCatalog/Crop';

import polaroid from 'assets/images/smartCatalog/polaroid.png';
import visualSearch from 'assets/images/visualSearch.svg';

import style from './index.module.scss';

interface VisualSearchProps {
  onSearch?: (f: File) => any;
  onExit?: () => any;
  file?: File;
  onFileChange?: (f: File) => any;
}

export const VisualSearch: FunctionComponent<VisualSearchProps> = ({ onSearch, onExit, file, onFileChange }) => {
  const { t } = useTranslation();

  return (
    <div className={style.main} onMouseDown={e => e.stopPropagation()}>
      {file ? (
        <Crop file={file} onCrop={onSearch} onExit={onExit} />
      ) : (
        <Dropzone
          accept={{ 'image/*': [] }}
          onDragEnter={(e: React.DragEvent<HTMLDivElement>) => {
            e.stopPropagation();
          }}
          maxFiles={1}
          minSize={1}
          noDragEventsBubbling
          onDropAccepted={acceptedFiles => {
            if (acceptedFiles.length == 1) onFileChange?.(acceptedFiles[0]);
          }}
          noClick={true}
        >
          {({ getRootProps, getInputProps, isDragAccept, open }) => {
            return (
              <div {...getRootProps()}>
                <div className={style.title}>{t('uploadImageToFind')}</div>
                <div className={style.polaroid}>
                  <img src={polaroid} draggable={false} />
                </div>
                <div className={style.upload}>
                  <button onClick={open}>
                    <img src={visualSearch} />
                    <div>{t('uploadImage')}</div>
                  </button>
                </div>
                <div className={`${style.accept} ${isDragAccept && style.acceptTrigger}`}>
                  <FiShare2 />
                </div>
                <input {...getInputProps()} />
              </div>
            );
          }}
        </Dropzone>
      )}
    </div>
  );
};
