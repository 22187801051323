import { Query, useRequest } from '@neovision/react-query';
import type { FunctionComponent } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobe, FaGoogle, FaImage } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { ErrorBoundary, Placeholder } from 'components/utils';
import { Popper } from 'components/utils/Popper';

import type { Match } from 'interfaces';

import { Path } from 'utils/paths';

import styles from './index.module.scss';

export const Matches: FunctionComponent = () => {
  const request = useRequest();
  const { t } = useTranslation();

  return (
    <ErrorBoundary>
      <div className={styles.main}>
        <div>
          <Query<number[]> query={`get_matching_scrap_images/?validated`}>
            {({ data: matchesPk, loading, error }) => {
              const [matchPk, setMatchPk] = useState<number>();

              useEffect(() => {
                if (!loading) setMatchPk(matchesPk[0]);
              }, [matchesPk, loading]);

              if (loading) return <Placeholder className={styles.placeholder} />;
              if (error) return <div>{error}</div>;
              return (
                <Fragment>
                  {matchesPk.length > 0 ? (
                    <Query<Match> query={`get_matches_for_one_image/?scrap_image_pk=${matchPk}&validated`}>
                      {({ data: match, loading, error }) => {
                        if (error) return <div>{error}</div>;

                        const handleCounterfeit = () => {
                          request<boolean>(`set_counterfeit/`, {
                            method: 'POST',
                            data: { scrap_image_pk: matchPk, pattern_pk: match.pattern.id, counterfeit: true },
                          }).then(removeFirstElement);
                        };

                        const handleMyCloth = () => {
                          request<boolean>(`set_counterfeit/`, {
                            method: 'POST',
                            data: { scrap_image_pk: matchPk, pattern_pk: match.pattern.id, legal: true },
                          }).then(removeFirstElement);
                        };

                        const handleNoMatch = () => {
                          request<boolean>(`set_validation_matching/`, {
                            method: 'POST',
                            data: { scrap_image_pk: matchPk, pattern_pk: match.pattern.id },
                          }).then(removeFirstElement);
                        };

                        const removeFirstElement = () => {
                          matchesPk.splice(0, 1);
                          setMatchPk(matchesPk[0]);
                        };

                        return (
                          <div className={styles.match}>
                            <Popper className={styles.popper} poperElement={<div>{t('seeGoogleImage')}</div>}>
                              <a href={match?.scrap.google_url ?? ''} draggable={false}>
                                {loading ? <Placeholder /> : <img src={`data:image/gif;base64,${match.scrap.scrap}`} draggable={false} />}
                                <div>
                                  <FaGoogle />
                                </div>
                              </a>
                            </Popper>
                            <Popper className={styles.popper} poperElement={<div>{t('seeWebsite')}</div>}>
                              <a href={match?.scrap.product_url ?? ''} draggable={false}>
                                {loading ? <Placeholder /> : <img src={`data:image/gif;base64,${match.scrap.scrap_zoom}`} draggable={false} />}
                                <div>
                                  <FaGlobe />
                                </div>
                              </a>
                            </Popper>
                            <Popper className={styles.popper} poperElement={<div>{t('showPattern')}</div>}>
                              <Link to={`/${Path.join('lockeoDetection', 'management', 'patterns')}/${match?.pattern.id}` ?? ''} draggable={false}>
                                {loading ? <Placeholder /> : <img src={`data:image/gif;base64,${match.pattern.thumbnail}`} draggable={false} />}
                                <div>
                                  <FaImage />
                                </div>
                              </Link>
                            </Popper>
                            <div>
                              <button onClick={handleMyCloth}>{t('myFabric')}</button>
                              <button onClick={handleCounterfeit}>{t('counterfeit')}</button>
                              <button onClick={handleNoMatch}>{t('notAMatch')}</button>
                            </div>
                          </div>
                        );
                      }}
                    </Query>
                  ) : (
                    <div>{t('noMoreMatch')}</div>
                  )}
                </Fragment>
              );
            }}
          </Query>
        </div>
      </div>
    </ErrorBoundary>
  );
};
