import type { FunctionComponent, InputHTMLAttributes } from 'react';
import { useState } from 'react';

import { useFilters, useView } from 'components/SmartCatalog/contexts';

import { useDebounce } from 'utils/functions';

import magnifyingGlass from 'assets/images/magnifyingGlass.svg';

import styles from './index.module.scss';

type SearchProps = InputHTMLAttributes<HTMLInputElement>;

export const Search: FunctionComponent<SearchProps> = props => {
  const [filters, setFilters] = useFilters();
  const [text, setText] = useState('');
  const [_, setView] = useView();

  useDebounce(
    () => {
      setFilters({ ...filters, text });
      setView('pattern');
    },
    500,
    [text],
  );

  return (
    <div className={styles.main}>
      <input {...props} value={text} onChange={e => setText(e.target.value)} />
      <img src={magnifyingGlass} draggable={false} />
    </div>
  );
};
