import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import form from '../../../scss/form.module.scss';
import { createFormObject, } from '../../../utils/form';
import { CRUD } from '../CRUD';
import { getDeleteForm } from '../Form/Delete';
import { getUpdateForm } from '../Form/Update';
import { StateFunction } from '../StateFunction';
import hash from 'object-hash';
import { Fragment, useMemo } from 'react';
export const CRUDAuto = ({ children, endpoints, type, ...options }) => {
    return (_jsx(CRUD, { endpoints: endpoints, ...options, children: (crud, forceRefresh) => {
            const { read: { data, loading, error }, handleCreate, handleUpdate, handleDelete, } = crud;
            const forms = useMemo(() => {
                if (loading)
                    return undefined;
                return {
                    CreateForm({ options: opts = {}, attributes, children } = {}) {
                        const { className, override = {}, ...options } = opts;
                        const mergedType = Object.merge({}, type, override);
                        return (_jsxs("form", { ...attributes, className: `${form.form} ${className} ${attributes?.className}`, onSubmit: (e) => {
                                attributes?.onSubmit?.(e);
                                handleCreate(e);
                            }, children: [createFormObject(mergedType, {
                                    method: 'create',
                                    formOptions: options,
                                }), children] }));
                    },
                    EntriesForm({ children, ...props }) {
                        const { override = {} } = props.options ?? {};
                        const mergedType = Object.merge({}, type, override);
                        const pkName = Object.entries(mergedType)
                            .filter(([_, value]) => value.pk)
                            .map(([k, v]) => v.name ?? k)[0];
                        const createForms = (data, value, index = 0) => {
                            return (_jsx(Fragment, { children: children?.({
                                    UpdateForm: getUpdateForm({
                                        data,
                                        handleUpdate,
                                        type: mergedType,
                                        ...props,
                                    }),
                                    DeleteForm: getDeleteForm({
                                        pkName,
                                        handleDelete,
                                        data,
                                        value,
                                        ...props,
                                    }),
                                    data,
                                    index,
                                }) }, index));
                        };
                        if (Array.isArray(data)) {
                            return (_jsx(_Fragment, { children: data.map((d, i) => {
                                    const value = d[pkName];
                                    return createForms(d, value, i);
                                }) }));
                        }
                        else
                            return createForms(data, data[pkName]);
                    },
                };
            }, [hash(type ?? {}), loading, data]);
            if (error)
                return _jsx("div", { children: error });
            if (loading)
                return _jsx("div", { children: "Loading..." });
            return (_jsx(StateFunction, { children: () => {
                    return children({
                        data: crud,
                        forms: forms,
                        forceRefresh,
                    });
                } }));
        } }));
};
