import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useQuery } from '../../../hooks/query';
import { parseLoader } from '../../../utils/api';
import ErrorBoundary from '../ErrorBoundary';
import { useQueryOptions } from '../QueryOptionsProvider';
import { StateFunction } from '../StateFunction';
/**
 * The query component is a fetch wrapper that allows to directly add logic in the design of the react DOM architecture
 */
export const Query = ({ children, loader: qLoader, ...qRest }) => {
    const [{ loader: oLoader }] = useQueryOptions();
    const { loading, ...qData } = useQuery(qRest);
    const loader = Object.merge(parseLoader(oLoader), parseLoader(qLoader));
    return (_jsx(ErrorBoundary, { children: loading && loader.autoload ? (_jsx(_Fragment, { children: loader.loader ?? _jsx("div", { children: "Loading data..." }) })) : (_jsx(StateFunction, { children: () => children({ loading, ...qData }) })) }));
};
