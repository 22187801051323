import { request, requestOptionsMerge } from '../../../../utils/api';
import { setCache } from '../../../../utils/cache';
import { isFormEvent } from '../../../../utils/form';
import { requestError, requestLog } from '../../../../utils/log';
import { formExtractor } from '../../../../utils/util';
import equal from 'fast-deep-equal';
export const updateRequest = ({ endpoint: { endpoint, mode, verbosity, domain, headers, idName, parameterType, ...eRest }, manualUpdate, data, onCompleted: onUpdated, type, forceRefresh, cacheKey, }) => {
    return (e, params = { name: idName }) => {
        const { method = 'PUT', name = idName, onRejected, } = requestOptionsMerge([eRest, params]);
        let formDatas;
        if (isFormEvent(e)) {
            e.preventDefault();
            formDatas = formExtractor(e.target, name);
        }
        else {
            formDatas = [e];
        }
        let newData;
        if (type == 'array')
            newData = [...data];
        else
            newData = data;
        const promises = [];
        formDatas.forEach((formData) => {
            const id = formData[name];
            const endpointId = `${endpoint}/${parameterType == 'path' ? `${id}/` : ''}`;
            let hasChanged = false;
            if (type == 'array') {
                const index = newData.findIndex((val) => val[name] == id);
                if (index < 0) {
                    requestError(`Element with ${name} ${id} not found.`);
                    throw new Error(`Element with ${name} ${id} not found.`);
                }
                const mergedData = {
                    ...newData[index],
                    ...formData,
                };
                hasChanged = !equal(newData[index], mergedData);
                newData[index] = mergedData;
            }
            else {
                newData = { ...newData, ...formData };
                hasChanged = !equal(newData, data);
            }
            if (hasChanged) {
                requestLog(mode, verbosity, 1, `[update][${method}]`, `${domain}/${endpointId}`, formData);
                promises.push(request(domain, endpointId, {
                    data: formData,
                    method,
                    headers,
                    mode,
                    onRejected,
                }));
            }
        });
        return Promise.all(promises).then(() => {
            manualUpdate?.(newData);
            setCache(cacheKey, newData);
            onUpdated?.() && forceRefresh?.();
            return newData;
        });
    };
};
