import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import neovision from 'assets/images/neovision.png';

export const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div>
        <div>
          <a href='https://textilia.fr/qui-sommes-nous/'>{t('about')}</a>
        </div>
        <div>
          <a href='https://textilia.fr/contact/'>{t('contactUs')}</a>
        </div>
        <div>
          <a href='https://textilia.fr/mentions-legales/'>{t('legalNotice')}</a>
        </div>
        <div>
          <a href='https://www.textilia.fr/politique-de-confidentialite/'>{t('privacy')}</a>
        </div>
      </div>
      <a href={`https://neovision.fr/`}>
        <p>Powered by</p>
        <img src={neovision} draggable={false} />
      </a>
    </footer>
  );
};
