import { contextGenerator } from '@generalizers/react-context';
import type { Dispatch, FunctionComponent, PropsWithChildren, SetStateAction } from 'react';

// Filters / File / ImageSrc

export const defaultFilters = {
  dateBegin: { name: 'Date début' },
  dateEnd: { name: 'Date fin' },
  books: [],
  rebracks: [],
  all_books: false,
  all_rebracks: false,
  tags: {
    // flower: { name: 'Fleur' },
    // heart: { name: 'Coeur' },
    // polkaDot: { name: 'Pois' },
    // rayures: { name: 'Rayures' },
  },
};

export const { useHook: useFilters, Provider: FiltersProvider, Consumer: FiltersConsumer } = contextGenerator<Filters>(defaultFilters, 'Filters');

export const getDefaultFilters = (addon: Partial<Filters>) => ({ ...defaultFilters, ...addon });

export const { useHook: useImage, Provider: ImageProvider, Consumer: ImageConsumer } = contextGenerator<File | undefined>(undefined, 'Image');
export const {
  useHook: useImageSrc,
  Provider: ImageSrcProvider,
  Consumer: ImageSrcConsumer,
} = contextGenerator<string | undefined>(undefined, 'ImageSrc');

export const FiltersImagesInitializer: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <FiltersProvider>
    <ImageProvider>
      <ImageSrcProvider>{children}</ImageSrcProvider>
    </ImageProvider>
  </FiltersProvider>
);

type State<T> = Dispatch<SetStateAction<T>>;
type StateArray<T> = [T, State<T>];
type FiltersImageArray = [StateArray<Filters>, StateArray<string | undefined>];

interface FiltersImages {
  children: (values: FiltersImageArray) => any;
}

export const useFiltersImages = () => [useFilters(), useImageSrc()] as FiltersImageArray;

export const FiltersImagesConsumer: FunctionComponent<FiltersImages> = ({ children }) => {
  return (
    <FiltersConsumer>
      {([filters, setFilters]) => (
        <ImageSrcConsumer>
          {([imageSrc, setImageSrc]) =>
            children([
              [filters, setFilters],
              [imageSrc, setImageSrc],
            ])
          }
        </ImageSrcConsumer>
      )}
    </FiltersConsumer>
  );
};

export interface FilterType {
  name: string;
  value?: number;
}

type Tags = { [k: string]: FilterType };

export interface Filters {
  dateBegin: FilterType;
  dateEnd: FilterType;
  books: number[];
  rebracks: number[];
  all_books: boolean;
  all_rebracks: boolean;
  tags: Tags;
  pattern_id?: number;
  text?: string;
}

// Page
export const { useHook: usePage, Provider: PageProvider, Consumer: PageConsumer } = contextGenerator<number>(1, 'Page');

export const {
  useHook: useVisualSearch,
  Provider: VisualSearchProvider,
  Consumer: VisualSearchConsumer,
} = contextGenerator<File | undefined>(undefined, 'VisualSearch');

export type View = 'pattern' | 'page' | 'rebrack';

export const {
  useHook: useView,
  Provider: ViewProvider,
  Consumer: ViewConsumer,
} = contextGenerator<View>((localStorage.getItem('catalogView') as View) ?? 'pattern', 'View');
