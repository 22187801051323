import { useMouse } from '@generalizers/react-events';
import { Skeleton } from '@mui/material';
import { Query, useRequest } from '@neovision/react-query';
import { useSnackbar } from 'notistack';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload, FaHeart } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { HiOutlineEye } from 'react-icons/hi';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Link, useParams } from 'react-router-dom';

import { SelectionMenu } from 'components/SmartCatalog/Selections/Menu';
import { Title } from 'components/SmartCatalog/utils/Title';
import { BlackButton } from 'components/utils/Button/BlackButton';

import type { EntityWithThumbnail, ExtractedPattern, Metadata, ShareType } from 'interfaces';
import type Pattern from 'interfaces/pattern/Pattern';
import { PatternType } from 'interfaces/pattern/Pattern';

import { downloadThumbnail } from 'utils/download';
import { riter } from 'utils/functions';
import { paths } from 'utils/paths';

import style from './index.module.scss';

export const Product: FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const request = useRequest();
  const { enqueueSnackbar } = useSnackbar();
  const [selectionUp, setSelectionUp] = useState(false);
  const [element, setElement] = useState<Element>();

  return (
    <Query<ExtractedPattern> query={`get_pattern_from_id?id=${id}`}>
      {({ data: product, loading: patternLoading, manualUpdate }) => {
        const [edit, setEdit] = useState(false);
        const [open, setOpen] = useState(false);
        const { id: productId, name, thumbnail, uploaded_at, metadata, page: pageId, rebrack: rebrackId, type } = product ?? {};

        useMouse('click', () => {
          setEdit(false);
          setOpen(false);
        });

        return (
          <Query<Metadata | undefined> query={`api/metadata/${metadata}`} active={!patternLoading} ignore={type == PatternType.Pattern}>
            {({ data: metadata, loading: metadataLoading }) => {
              const {
                id: metadataId,
                name: metadataName,
                building_location,
                cloth_type,
                collection,
                other,
                room_location,
                shelf_location,
                site_location,
                year,
              } = metadata ?? {};

              const metadataComp = (site_location || building_location || room_location || shelf_location) && (
                <div>
                  <label>Localisation</label>
                  {site_location && (
                    <div>
                      <label>{t('site')}</label>
                      <div>{site_location}</div>
                    </div>
                  )}
                  {building_location && (
                    <div>
                      <label>{t('building')}</label>
                      <div>{building_location}</div>
                    </div>
                  )}
                  {room_location && (
                    <div>
                      <label>{t('room')}</label>
                      <div>{room_location}</div>
                    </div>
                  )}
                  {shelf_location && (
                    <div>
                      <label>{t('shelf')}</label>
                      <div>{shelf_location}</div>
                    </div>
                  )}
                </div>
              );

              const metadataTypeComp = (
                <>
                  <div>
                    <label>{t('year')}</label>
                    <div>{year}</div>
                  </div>
                  {collection && (
                    <div>
                      <label>Collection</label>
                      <div>{collection}</div>
                    </div>
                  )}
                  {cloth_type && (
                    <div>
                      <label>{t('fabricType')}</label>
                      <div>{cloth_type}</div>
                    </div>
                  )}
                </>
              );

              return (
                <Query<EntityWithThumbnail | undefined>
                  query={`get_${type == PatternType.Book ? 'page' : 'rebrack'}_from_id?id=${type == PatternType.Book ? pageId : rebrackId}`}
                  active={!metadataLoading && !patternLoading}
                  ignore={type == PatternType.Pattern}
                >
                  {({ data: pageOrRebrack, loading }) => {
                    return (
                      <div className={style.main}>
                        <div className={style.left}>
                          <div className={style.pattern}>
                            {patternLoading ? (
                              <Skeleton variant='rectangular' />
                            ) : (
                              <div className={style.image}>
                                <img src={`data:image/png;base64,${thumbnail}`} draggable={false} />
                                <div className={style.patternHover}>
                                  <Link to={`/${paths.catalog}/search/${productId}`}>{t('similarProducts')}</Link>
                                </div>
                              </div>
                            )}
                          </div>
                          {type == PatternType.Rebrack && (
                            <Query<number[]> query={`get_pattern_ids_from_rebrack?rebrack_id=${rebrackId}`}>
                              {({ data: patternIds, loading: rebrackIdsLoading }) => {
                                const ids = (patternIds ?? [...new Array(4)]).filter(p => p == undefined || (id != undefined && p != parseInt(id)));
                                const [page, setPage] = useState(0);

                                const max = Math.ceil(ids.length / 4);

                                const handlePage = (n: number) => () => {
                                  if (n >= 0 && n < max) setPage(n);
                                };

                                return (
                                  <div className={style.similar}>
                                    <div className={style.arr}>
                                      {page != 0 && (
                                        <div onClick={handlePage(page - 1)}>
                                          <IoIosArrowBack />
                                        </div>
                                      )}
                                    </div>
                                    <div className={style.patterns}>
                                      <div style={{ transform: `translateX(-${page * 100}%)` }}>
                                        {ids.map((patternId, i) => {
                                          return (
                                            <Query<Pattern>
                                              key={i}
                                              query={`get_pattern_from_id?id=${patternId}&thumbnail=true`}
                                              active={!rebrackIdsLoading}
                                            >
                                              {({ data: pattern, loading: rebrackPatternLoading }) => {
                                                return (
                                                  <div>
                                                    <div>
                                                      {rebrackPatternLoading ? (
                                                        <Skeleton variant='rectangular' />
                                                      ) : (
                                                        <Link to={`/${paths.catalog}/${paths.product}/${pattern.id}`}>
                                                          <img src={`data:image/png;base64,${pattern.thumbnail}`} draggable={false} />
                                                        </Link>
                                                      )}
                                                    </div>
                                                  </div>
                                                );
                                              }}
                                            </Query>
                                          );
                                        })}
                                      </div>
                                    </div>
                                    <div className={style.arr}>
                                      {page != max - 1 && (
                                        <div onClick={handlePage(page + 1)}>
                                          <IoIosArrowForward />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              }}
                            </Query>
                          )}
                        </div>
                        <div className={style.right}>
                          <div className={style.rightTexts}>
                            {patternLoading ? (
                              riter(7, <Skeleton variant='rectangular' sx={{ margin: 2 }} />)
                            ) : (
                              <>
                                <Title
                                  value={name}
                                  edit={edit}
                                  handleEdit={edit => {
                                    setEdit(edit);
                                  }}
                                  handleSubmit={e => {
                                    const name = e.target['value'];
                                    request('rename_pattern/', { method: 'PATCH', data: { id: productId, name } }).then(() =>
                                      manualUpdate({ ...product, name }),
                                    );
                                  }}
                                />
                                <div className={style.rightInfo}>
                                  <div>
                                    <label>{type == PatternType.Book ? t('book') : type == PatternType.Pattern ? t('pattern') : 'Rebrack'}</label>
                                    <div>
                                      <label>{t('name')}</label>
                                      <div>
                                        {type == PatternType.Pattern ? (
                                          <div>{name}</div>
                                        ) : (
                                          <Link
                                            to={`/${paths.catalog}/${type == PatternType.Book ? 'book' : 'rebracks'}/${
                                              type == PatternType.Rebrack ? `${metadataId}/${rebrackId}` : metadataId
                                            }`}
                                          >
                                            {type == PatternType.Rebrack ? pageOrRebrack?.name : metadataName}
                                          </Link>
                                        )}
                                        {type == PatternType.Rebrack && (
                                          <button
                                            onClick={e => {
                                              e.stopPropagation();
                                              setOpen(true);
                                            }}
                                          >
                                            <HiOutlineEye />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                    {type == PatternType.Book && (
                                      <div>
                                        <label>Page</label>
                                        <div>
                                          {!loading && <Link to={`/catalog/books/${metadata?.id}/${pageOrRebrack?.id}`}>{pageOrRebrack?.name}</Link>}
                                          <button
                                            onClick={e => {
                                              e.stopPropagation();
                                              setOpen(true);
                                            }}
                                          >
                                            <HiOutlineEye />
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                    {type != PatternType.Pattern && metadataTypeComp}
                                  </div>
                                  {type != PatternType.Pattern && metadataComp}
                                  <div className={style.pageImg} style={{ opacity: open ? 1 : 0, pointerEvents: open ? 'all' : 'none' }}>
                                    {!loading && type != PatternType.Pattern && <img src={`data:image/png;base64,${pageOrRebrack?.thumbnail}`} />}
                                  </div>
                                </div>
                                <div>
                                  <label>{t('uploadDate')}</label>
                                  <div>{uploaded_at}</div>
                                </div>
                              </>
                            )}
                          </div>
                          <hr />
                          <div className={style.rightUtils}>
                            <BlackButton
                              onClick={() => {
                                request<ShareType>(`get_pattern_zip_url?pattern_id=${productId}`).then(({ url }) => {
                                  navigator.clipboard.writeText(url).then(() => enqueueSnackbar(t('copiedShareLink'), { variant: 'success' }));
                                });
                              }}
                            >
                              <FiShare2 />
                              <div>{t('share')}</div>
                            </BlackButton>
                            <BlackButton
                              onClick={() => {
                                downloadThumbnail(thumbnail, name, PatternType.Book == type ? '.png' : '.jpg');
                              }}
                            >
                              <FaDownload />
                              <div>{t('download')}</div>
                            </BlackButton>
                            <BlackButton onClick={() => setSelectionUp(true)}>
                              <FaHeart />
                              <div ref={r => r && setElement(r)}>{t('addToSelection')}</div>
                              <SelectionMenu element={element} onClose={() => setSelectionUp(false)} open={selectionUp} id={productId} />
                            </BlackButton>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};
