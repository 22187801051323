import styles from '../index.module.scss';
import { useMouse, useTrigger } from '@generalizers/react-events';
import { TextField, Tooltip } from '@mui/material';
import { Query, StateFunction, useRequest } from '@neovision/react-query';
import i18next from 'i18next';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineEye } from 'react-icons/hi';
import { RxCross1 } from 'react-icons/rx';
import { useNavigate, useParams } from 'react-router-dom';
import stc from 'string-to-color';

import { Ariane } from 'components/SmartCatalog/Ariane';
import { BookFilter } from 'components/SmartCatalog/Catalog/BookFilter';
import { PageList } from 'components/SmartCatalog/Catalog/Items/Pages/List';
import { PatternList } from 'components/SmartCatalog/Catalog/Items/Patterns/List';
import { RebrackList } from 'components/SmartCatalog/Catalog/Items/Rebracks/List';
import type { View } from 'components/SmartCatalog/contexts';
import { getDefaultFilters, useFiltersImages, usePage, useView, useVisualSearch } from 'components/SmartCatalog/contexts';
import { Popup } from 'components/utils';
import { BlackButton } from 'components/utils/Button/BlackButton';
import { Dropdown } from 'components/utils/Dropdown';

import type { Metadata, Pattern } from 'interfaces';
import type { Rebrack } from 'interfaces/Rebrack';

import { useDebounce } from 'utils/functions';
import { paths } from 'utils/paths';

interface Filter {
  name: string;
  checked: boolean;
}

const maxShownBooksFilter = 4;
export const minBookDate = 1700;
export const maxBookDate = new Date().getFullYear() + 1;

export const isValidBookDate = (n: number) => {
  return n >= minBookDate && n <= maxBookDate;
};

const validateDates = (begin: number = minBookDate, end: number = maxBookDate) => {
  const validBegin = isValidBookDate(begin) && begin <= (end ?? maxBookDate);
  const validEnd = isValidBookDate(end) && end >= (begin ?? minBookDate);
  return validBegin && validEnd;
};

export type Size = 'small' | 'medium' | 'big';

export const Catalog: FunctionComponent = () => {
  const { searchPatternId, bookId, rebrackId } = useParams();
  const { t } = useTranslation();
  const [size, setSize] = useState<Size>('medium');
  const [dateBegin, setDateBegin] = useState<number>();
  const [dateEnd, setDateEnd] = useState<number>();
  const [selectedBooks, setSelectedBooks] = useState<number[]>([]);
  const [selectedRebracks, setSelectedRebracks] = useState<number[]>([]);
  const [selectedAllBooks, setSelectedAllBooks] = useState(false);
  const [[filters, setFilters], [imageSrc, setImageSrc]] = useFiltersImages();
  const [image, setImage] = useVisualSearch();
  const [_, setPage] = usePage();
  const [patternFilters, setPatternFilters] = useState<Filter[]>([]);
  const [colorFilters, setColorFilters] = useState(['red', 'blue', 'green', 'orange', 'yellow', ''].map(v => ({ name: v, checked: false })));
  const [selectedAllPatterns, setSelectedAllPatterns] = useState(false);
  const [patternSearch, setPatternSearch] = useState('');
  const [view, _setView] = useView();

  const navigate = useNavigate();
  const request = useRequest();

  const handleUpdateFilters = () => setFilters({ ...filters });
  useDebounce(handleUpdateFilters, 500, [selectedRebracks, selectedAllBooks, selectedAllPatterns, dateBegin, dateEnd]);

  useEffect(() => {
    setSelectedAllBooks(filters.all_books);
    setSelectedBooks([...filters.books]);
    setSelectedRebracks([...filters.rebracks]);
    setDateBegin(filters.dateBegin.value);
    setDateEnd(filters.dateEnd.value);
  }, []);

  useEffect(() => {
    setPatternFilters([t('polkadot'), t('checkered'), t('flowers')].map(v => ({ name: v, checked: false })));
  }, [i18next.resolvedLanguage]);

  useEffect(() => {
    if (image) {
      const fr = new FileReader();
      fr.addEventListener('load', () => fr.result && setImageSrc(fr.result.toString()));
      fr.readAsDataURL(image);
    }
  }, [image]);

  useEffect(() => {
    if (imageSrc == undefined) {
      filters.pattern_id = undefined;
      handleUpdateFilters();
    }
  }, [imageSrc]);

  useEffect(() => {
    if (searchPatternId != undefined) {
      setView('pattern');
      setCatalog();
      filters.pattern_id = parseInt(searchPatternId);
      request<Pattern>(`get_pattern_from_id?id=${searchPatternId}&thumbnail=true`).then(p => {
        setImageSrc(`data:image/gif;base64,${p?.thumbnail}`);
        setImage(undefined);
      });
      handleUpdateFilters();
    }
  }, [searchPatternId]);

  useEffect(() => {
    setPage(1);
  }, [image, imageSrc, searchPatternId, filters]);

  const setView = (v: View) => {
    if (v != 'pattern') {
      setImageSrc(undefined);
      setImage(undefined);
    }
    if (v != view) navigate(`/${paths.catalog}/`, { replace: true });
    localStorage.setItem('catalogView', v);
    _setView(v);
  };

  const setCatalog = () => navigate(`/${paths.catalog}`, { replace: true });

  const correctFrom = !!(dateBegin == undefined || (dateBegin && isValidBookDate(dateBegin) && dateBegin <= (filters.dateEnd.value ?? maxBookDate)));
  const correctTo = !!(dateEnd == undefined || (dateEnd && isValidBookDate(dateEnd) && dateEnd >= (filters.dateBegin.value ?? minBookDate)));

  const hasBookFilters = selectedAllBooks || selectedBooks.length > 0;
  const hasRebrackFilters = filters.all_rebracks || selectedRebracks.length > 0;

  useEffect(() => {
    if (view != 'pattern') {
      if (
        (hasBookFilters && !hasRebrackFilters && view != 'page') ||
        (hasRebrackFilters && !hasBookFilters && view != 'rebrack') ||
        (!hasRebrackFilters && !hasBookFilters) ||
        (hasBookFilters && hasRebrackFilters)
      )
        setView('pattern');
    }
  }, [hasBookFilters, hasRebrackFilters, view]);

  useEffect(() => {
    if (bookId) {
      setCatalog();
      const book = parseInt(bookId);
      setSelectedBooks([book]);
      setFilters(getDefaultFilters({ books: [book] }));
    }
  }, [bookId]);

  useEffect(() => {
    if (rebrackId) {
      setCatalog();
      const rebrack = parseInt(rebrackId);
      setSelectedRebracks([rebrack]);
      setFilters(getDefaultFilters({ rebracks: [rebrack] }));
    }
  }, [rebrackId]);

  return (
    <>
      <div className={styles.bar}>
        <div className={styles.activeFilters}>
          <div>{t('filters')}</div>
          <div>
            {imageSrc && (
              <div className={styles.visualSearch}>
                <div>
                  <img src={imageSrc} draggable={false} />
                </div>
                <RxCross1 onClick={() => (setImage(undefined), setImageSrc(undefined), navigate(`/${paths.catalog}`))} />
              </div>
            )}
            {/* KEEP FOR FUTUR */}
            {/* {Object.entries(filters.tags)
              .filter(([_, v]) => v.value)
              .map(([k, { name }], i) => {
                return (
                  <div
                    key={`filterTag-${i}`}
                    onClick={() => {
                      filters.tags[k].value = undefined;
                      setFilters({ ...filters });
                    }}
                  >
                    {name}
                  </div>
                );
              })} */}
          </div>
        </div>
        <hr />
        <div className={styles.filters}>
          <div>
            <Dropdown
              className={styles.dropdown}
              open={true}
              element={open => (
                <div className={styles.dropdownElement}>
                  <div>{t('drawing')}</div>
                  <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                </div>
              )}
            >
              <div className={`${styles.dropdownContent} ${styles.dropdownDrawing}`}>
                {patternFilters.map((filter, i) => {
                  return (
                    <div key={i}>
                      <input type='checkbox' />
                      <label>{filter.name}</label>
                    </div>
                  );
                })}
              </div>
            </Dropdown>
            <hr />
            <Dropdown
              className={`${styles.dropdown} ${styles.dropdownContentColor}`}
              open={true}
              element={open => (
                <div className={styles.dropdownElement}>
                  <div>{t('colors')}</div>
                  <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                </div>
              )}
            >
              <div className={`${styles.dropdownContent} ${styles.dropdownColor}`}>
                {colorFilters.map((filter, i) => {
                  const className = `color-${filter.name}`;
                  return (
                    <div key={i}>
                      <input className={className} type='checkbox' />
                      <style>
                        {`.${className}[type=checkbox]`}
                        {`{background-color:${stc(className)};}`}
                        {`.${className}[type=checkbox]:checked`}
                        {`{background-color:${stc(className)};}`}
                      </style>
                    </div>
                  );
                })}
              </div>
            </Dropdown>
            <hr />
            <Dropdown
              className={styles.dropdown}
              open={true}
              watchResize
              element={open => (
                <div className={styles.dropdownElement}>
                  <div>Date</div>
                  <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                </div>
              )}
            >
              <div className={`${styles.dropdownContent} ${styles.dropdownDates}`}>
                <div>
                  <label>{t('from')}</label>
                  <TextField
                    className={styles.dateInput}
                    error={!correctFrom}
                    type='number'
                    label={t('year')}
                    InputProps={{ inputProps: { min: minBookDate, max: dateEnd ?? '' } }}
                    variant='standard'
                    helperText={!correctFrom && t('yearFormat', { begin: minBookDate, end: filters.dateEnd.value ?? maxBookDate })}
                    value={dateBegin ?? ''}
                    size={'small'}
                    FormHelperTextProps={{ style: { fontSize: '0.6em' } }}
                    onChange={({ target }) => {
                      const value = parseInt(target.value);
                      setDateBegin(value);
                      if (target.value == '') {
                        setDateBegin(undefined);
                        filters.dateBegin.value = undefined;
                      } else if (validateDates(value, filters.dateEnd.value)) {
                        filters.dateBegin.value = value;
                        if (validateDates(value, dateEnd)) filters.dateEnd.value = dateEnd;
                        else filters.dateEnd.value = undefined;
                      }
                    }}
                  />
                </div>
                <div>
                  <label>{t('to')}</label>
                  <TextField
                    className={styles.dateInput}
                    error={!correctTo}
                    type='number'
                    label={t('year')}
                    InputProps={{ inputProps: { min: dateBegin ?? minBookDate, max: maxBookDate } }}
                    variant='standard'
                    helperText={!correctTo && t('yearFormat', { begin: filters.dateBegin.value ?? minBookDate, end: maxBookDate })}
                    size={'small'}
                    FormHelperTextProps={{ style: { fontSize: '0.6em' } }}
                    value={dateEnd ?? ''}
                    onChange={({ target }) => {
                      const value = parseInt(target.value);
                      setDateEnd(value);
                      if (target.value == '') {
                        setDateEnd(undefined);
                        filters.dateEnd.value = undefined;
                      } else if (validateDates(filters.dateBegin.value, value)) {
                        filters.dateEnd.value = value;
                        if (validateDates(dateBegin, value)) filters.dateBegin.value = dateBegin;
                        else filters.dateBegin.value = undefined;
                      }
                    }}
                  />
                </div>
              </div>
            </Dropdown>
            <hr />
            <StateFunction>
              {() => {
                const [up, setUp] = useState(false);
                const [open, setOpen] = useState(false);

                return (
                  <Query<Metadata[]>
                    query={`api/metadata/${
                      filters.dateBegin
                        ? `?filters=${JSON.stringify({ dateBegin: filters.dateBegin?.value, dateEnd: filters.dateEnd?.value, type: 'book' })}`
                        : ''
                    }`}
                    memoizedLoading
                  >
                    {({ data, loading }) => {
                      const books = data ?? [];
                      const [search, setSearch] = useState('');
                      const [showMore, triggerShowMore] = useTrigger(false);

                      const handleCheck = (b?: number) => () => {
                        if (b != undefined) {
                          if (selectedBooks.includes(b)) {
                            selectedBooks.remove(b);
                            filters.books.remove(b);
                          } else {
                            selectedBooks.push(b);
                            filters.books.push(b);
                          }
                          setSelectedBooks([...selectedBooks]);
                          filters.all_books = false;
                          setSelectedAllBooks(false);
                        } else {
                          filters.books = [];
                          setSelectedBooks([]);
                          filters.all_books = !selectedAllBooks;
                          setSelectedAllBooks(!selectedAllBooks);
                        }
                        handleUpdateFilters();
                      };

                      useEffect(() => {
                        if (bookId != undefined && !loading) {
                          setImage(undefined);
                          setImageSrc(undefined);
                          setView('page');
                          setSize('big');
                          filters.books = [];
                          handleCheck(parseInt(bookId))();
                        }
                      }, [bookId, loading]);

                      useMouse(
                        'click',
                        () => {
                          triggerShowMore(false);
                        },
                        [showMore],
                      );

                      if (loading || books.length == 0) return <></>;

                      const handleSearch = e => {
                        setSearch(e.target.value);
                      };

                      return (
                        <>
                          <Dropdown
                            className={styles.dropdown}
                            watchResize={true}
                            open={open}
                            handleOpen={o => setOpen(o)}
                            element={open => (
                              <div className={styles.dropdownElement}>
                                <div>{t('books')}</div>
                                <div className={styles.dropdownElementBook}>
                                  <HiOutlineEye
                                    onClick={e => {
                                      e.stopPropagation();
                                      setUp(true);
                                    }}
                                  />
                                  <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                                </div>
                              </div>
                            )}
                          >
                            <div className={`${styles.dropdownContent} ${styles.dropdownBooks}`}>
                              <TextField
                                className={styles.searchBook}
                                label={t('searchForBooks')}
                                variant='filled'
                                value={search}
                                onChange={handleSearch}
                                type='search'
                                size='small'
                                InputLabelProps={{ style: { pointerEvents: 'none' } }}
                              />
                              {search == '' && (
                                <div>
                                  <input type='checkbox' onChange={handleCheck()} checked={selectedAllBooks} />
                                  <label>{t('all')}</label>
                                </div>
                              )}
                              {books
                                .sort((b1, b2) => {
                                  if (selectedBooks.includes(b1.id)) {
                                    if (selectedBooks.includes(b2.id)) return b1.name.localeCompare(b2.name);
                                    return -1;
                                  } else if (selectedBooks.includes(b2.id)) return 1;
                                  return b1.name.localeCompare(b2.name);
                                })
                                .filter((b, i) => {
                                  if (showMore) return true;
                                  return (
                                    (i < maxShownBooksFilter || selectedBooks.includes(b.id)) &&
                                    (search == '' || b.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                                  );
                                })
                                .map((b, i) => {
                                  const r = `book-${i}`;
                                  return (
                                    <Tooltip key={i} title={b.name} placement='right'>
                                      <div>
                                        <input id={r} type='checkbox' onChange={handleCheck(b.id)} checked={selectedBooks.includes(b.id)} />
                                        <label htmlFor={r}>{b.name}</label>
                                      </div>
                                    </Tooltip>
                                  );
                                })}
                            </div>
                            <div className={styles.showPlus}>
                              <BlackButton
                                onClick={e => {
                                  e.stopPropagation();
                                  triggerShowMore();
                                }}
                              >
                                {t('showMore')}
                              </BlackButton>
                            </div>
                            <Popup className={styles.popup} up={up} onClose={() => setUp(false)}>
                              <BookFilter
                                books={books}
                                onFilter={books => {
                                  setSelectedAllBooks(false);
                                  filters.all_books = false;
                                  setSelectedBooks([...books]);
                                  filters.books = [...books];
                                  setUp(false);
                                  handleUpdateFilters();
                                  if (books.length != 0) setOpen(true);
                                }}
                              />
                            </Popup>
                          </Dropdown>
                          <hr />
                        </>
                      );
                    }}
                  </Query>
                );
              }}
            </StateFunction>
            <Query<Rebrack[]>
              query={`api/rebracks/${
                filters.dateBegin ? `?filters=${JSON.stringify({ dateBegin: filters.dateBegin?.value, dateEnd: filters.dateEnd?.value })}` : ''
              }`}
              memoizedLoading
            >
              {({ data: rebracks, loading }) => {
                const [search, setSearch] = useState('');
                const [open, setOpen] = useState(false);

                if (loading || rebracks.length == 0) return <></>;

                const handleCheck = (b?: number) => () => {
                  if (b != undefined) {
                    if (selectedRebracks.includes(b)) {
                      selectedRebracks.remove(b);
                      filters.rebracks.remove(b);
                    } else {
                      selectedRebracks.push(b);
                      filters.rebracks.push(b);
                    }
                    setSelectedRebracks([...selectedRebracks]);
                    filters.all_rebracks = false;
                  } else {
                    filters.rebracks = [];
                    setSelectedRebracks([]);
                    filters.all_rebracks = !filters.all_rebracks;
                  }
                  setFilters({ ...filters });
                };

                const handleSearch = e => {
                  setSearch(e.target.value);
                };

                return (
                  <>
                    {loading || rebracks.length == 0 ? (
                      <></>
                    ) : (
                      <>
                        <Dropdown
                          className={styles.dropdown}
                          watchResize={true}
                          open={open}
                          handleOpen={o => setOpen(o)}
                          element={open => (
                            <div className={styles.dropdownElement}>
                              <div>Rebracks</div>
                              <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                            </div>
                          )}
                        >
                          <div className={`${styles.dropdownContent} ${styles.dropdownBooks}`}>
                            <TextField
                              className={styles.searchBook}
                              label={t('searchForRebracks')}
                              variant='filled'
                              value={search}
                              onChange={handleSearch}
                              type='text'
                              size='small'
                              InputLabelProps={{ style: { pointerEvents: 'none' } }}
                            />
                            {search == '' && (
                              <div>
                                <input type='checkbox' onChange={handleCheck()} checked={filters.all_rebracks} />
                                <label>{t('all')}</label>
                              </div>
                            )}
                            {rebracks
                              .sort((b1, b2) => {
                                if (selectedRebracks.includes(b1.id)) {
                                  if (selectedRebracks.includes(b2.id)) return b1.name.localeCompare(b2.name);
                                  return -1;
                                } else if (selectedRebracks.includes(b2.id)) return 1;
                                return b1.name.localeCompare(b2.name);
                              })
                              .filter((b, i) => {
                                return (
                                  (i < maxShownBooksFilter || selectedRebracks.includes(b.id)) &&
                                  (search == '' || b.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
                                );
                              })
                              .map((b, i) => {
                                return (
                                  <div key={i}>
                                    <input
                                      type='checkbox'
                                      id={`rebrack-${b.name}`}
                                      onChange={handleCheck(b.id)}
                                      checked={selectedRebracks.includes(b.id)}
                                    />
                                    <label htmlFor={`rebrack-${b.name}`}>{b.name}</label>
                                  </div>
                                );
                              })}
                          </div>
                        </Dropdown>
                        <hr />
                      </>
                    )}
                  </>
                );
              }}
            </Query>
            <Dropdown
              className={styles.dropdown}
              open={false}
              element={open => (
                <div className={styles.dropdownElement}>
                  <div>{t('patterns')}</div>
                  <div style={{ transform: `translateY(${open ? '18%' : '-18%'})` }}>{open ? '\u2303' : '\u2304'}</div>
                </div>
              )}
            >
              <StateFunction>
                {() => {
                  const handlePatternSearch = e => {
                    setPatternSearch(e.target.value);
                  };

                  return (
                    <div className={`${styles.dropdownContent} ${styles.dropdownPattern}`}>
                      <TextField
                        className={styles.searchBook}
                        label={t('searchForPatterns')}
                        variant='filled'
                        value={patternSearch}
                        onChange={handlePatternSearch}
                        type='search'
                        size='small'
                        InputLabelProps={{ style: { pointerEvents: 'none' } }}
                      />
                      <div>
                        <input type={'checkbox'} onChange={() => setSelectedAllPatterns(!selectedAllPatterns)} checked={selectedAllPatterns} />
                        <label>{t('all')}</label>
                      </div>
                    </div>
                  );
                }}
              </StateFunction>
            </Dropdown>

            {/* KEEP FOR LATER VERSION */}
            {/* <div>
              <label>{t('book')}</label>
              <div>
                <Query<Book[]> query={`api/books/`}>
                  {({ data: books, loading, error }) => {
                    if (loading) return <div>Loading...</div>;
                    if (error) return <div>{t('cantRetrieveBooks')}</div>;

                    return (
                      <select>
                        <option disabled value={undefined}>
                          {t('selectBook')}
                        </option>
                        {books.map((book, i) => {
                          return (
                            <option key={`book-${i}`} value={book.id}>
                              {book.name}
                            </option>
                          );
                        })}
                      </select>
                    );
                  }}
                </Query>
              </div>
            </div> */}
            <div>
              {/* KEEPING TAGS FOR LATER VERSION */}
              {/* <label>Tags</label>
              <div>
                {Object.entries(filters.tags)
                  .filter(([_, v]) => !v.value)
                  .map(([k, { name }], i) => {
                    return (
                      <TagButton
                        key={`tag-${i}`}
                        color={stc(name)}
                        onClick={() => {
                          filters.tags[k].value = k;
                          setFilters({ ...filters });
                        }}
                      >
                        {name}
                      </TagButton>
                    );
                  })}
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles.order}>
          {/* KEEPING TAGS FOR LATER VERSION */}
          {/* <div>{t('sort')}</div>
          <div>{t('inDevelopment')}</div> */}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <Ariane />
          <div className={styles.display}>
            <div>{t('view')}</div>
            {hasBookFilters != hasRebrackFilters && (
              <div className={styles.view}>
                <button className={view == 'pattern' ? styles.selected : ''} onClick={() => setView('pattern')}>
                  {t('pattern')}
                </button>
                {hasBookFilters && !hasRebrackFilters && (
                  <button className={view == 'page' ? styles.selected : ''} onClick={() => setView('page')}>
                    Page
                  </button>
                )}
                {hasRebrackFilters && !hasBookFilters && (
                  <button className={view == 'rebrack' ? styles.selected : ''} onClick={() => setView('rebrack')}>
                    Rebrack
                  </button>
                )}
              </div>
            )}
            <div className={styles.view}>
              <button className={size == 'small' ? styles.selected : ''} onClick={() => setSize('small')}>
                {t('small')}
              </button>
              <button className={size == 'medium' ? styles.selected : ''} onClick={() => setSize('medium')}>
                {t('medium')}
              </button>
              <button className={size == 'big' ? styles.selected : ''} onClick={() => setSize('big')}>
                {t('big')}
              </button>
            </div>
          </div>
        </div>
        {view == 'pattern' ? <PatternList size={size} /> : view == 'page' ? <PageList size={size} /> : <RebrackList size={size} />}
      </div>
    </>
  );
};
